/* eslint-disable no-console */
import history from './history';
import auth0 from 'auth0-js';
import config from './config';
import React from 'react';
import Cookies from 'js-cookie';

const AuthContext = React.createContext({ test: 'test' });
const EXPIRATION_TOKEN_KEY = 'expirationTokenDate';
const EXPIRATION_TOKEN_COOKIE_DOMAIN = '.misp-solutions.com';


export class Auth {
  accessToken;
  idToken;
  expiresAt;
  userProfile;

  auth0 = new auth0.WebAuth(config.auth)

  constructor() {
    this.login = this.login.bind(this);
    this.logoutLocal = this.logoutLocal.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);

    this.renewSession().catch(err => {
      if (err.error !== 'login_required') {
        console.log('Authorization, renew: ' + err.error);
        this.logoutLocal();
      }
    });

    setInterval(() => {
      this.renewSession().catch(err => {
        if (err.error !== 'login_required') {
          console.log('Authorization, renewInt: ' + err.error);
          this.logoutLocal();
        }
      });
    }, config.auth.pollingInterval * 60 * 1000);
  }

  login(serviceName) {
    this.auth0.authorize();
  }

  // getUserDetails = async () => {
  //   let user_id = window.localStorage.getItem('user_id');
  //   let userData;

  //   try {
  //     const res = await fetch(`${BASIC}` + user_id, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'x-api-key': 'ofqDv7X0AR9hdqH4MEH7l4Q6oCIBsYlD9pbxdbOb',
  //         'Authorization': 'Bearer ' + user_id
  //       }
  //     })
  //     .then(res => res.json())
  //     .then(
  //       (result) => {
  //           userData = result;
  //           return result;
  //       },
  //       (error) => {
  //           return error;
  //       }
  //     )
  //   } catch (err) {
  //     return err;
  //   }

  //   return userData;
  // }

  // async getUserProfile() {
  //   var accessToken = window.localStorage.getItem('accessToken');
  //   if (accessToken) {
  //     await this.auth0.client.userInfo(accessToken, (err, profile) => {
  //       if (err) console.log('error : ', err);
  //       // window.localStorage.setItem('user_id', ((profile && profile.sub) || window.localStorage.getItem('user_id')));
  //       // this.getUserDetails();
  //       return profile;
  //     });
  //   }
  // }

  setAccessToken(accessToken) {
    this.accessToken = accessToken;
  }

  handleAuthentication() {
    this.auth0.parseHash(
      { __enableIdPInitiatedLogin: true },
      (err, authResult) => {
        if (authResult && authResult.accessToken) {
          if (authResult.idToken) {
            this.setSession(authResult);
          } else {
            this.renewSession();
          }
        } else if (err) {
          history.replace('/');
          console.log(err);
          console.error(
            `Error: ${err.error}. Check the console for further details.`
          );
        }
      }
    );
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }


  setSession(authResult) {
    function getAccountDescription(accessType, homeOffice, billTo, shipTo) {
      if (!accessType) {
        return '';
      }

      switch (accessType) {
        case 'bt':
          return `Bill To: ${billTo ? billTo : ''}`;

        case 'st':
          return `Ship To: ${shipTo ? shipTo : ''}`;

        case 'ho':
          return `Home Office: ${homeOffice ? homeOffice : ''}`;

        default:
          return accessType;
      }
    }

    // Set the time that the access token will expire at
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    Cookies.set(EXPIRATION_TOKEN_KEY, String(authResult.idTokenPayload ? authResult.idTokenPayload.exp : Math.floor(expiresAt/1000)), { expires: 7, domain: EXPIRATION_TOKEN_COOKIE_DOMAIN });
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;

    // Set user info
    if (authResult.idTokenPayload) {
      if (authResult.idTokenPayload['https://misp-solutions.com/name']) {
        const accessType = authResult.idTokenPayload['https://misp-solutions.com/accessType'];
        const email = authResult.idTokenPayload['https://misp-solutions.com/email'];
        const language = authResult.idTokenPayload['https://misp-solutions.com/language'];
        const name = authResult.idTokenPayload['https://misp-solutions.com/name'];
        const homeOffice = authResult.idTokenPayload['https://misp-solutions.com/homeOffice'];
        const billTo = authResult.idTokenPayload['https://misp-solutions.com/bill_to'];
        const billTos = authResult.idTokenPayload['https://misp-solutions.com/bill_tos'];
        const shipTo = authResult.idTokenPayload['https://misp-solutions.com/ship_to'];
        const shipTos = authResult.idTokenPayload['https://misp-solutions.com/ship_tos'];
        const userRole = authResult.idTokenPayload['https://misp-solutions.com/userRole'];
        const issuer = authResult.idTokenPayload['https://misp-solutions.com/issuer'];
        const app = authResult.idTokenPayload['https://misp-solutions.com/app'];

        const accountDescription = getAccountDescription(
          accessType,
          homeOffice,
          billTo,
          shipTo,
        );

        this.userProfile = {
          accessType,
          email,
          language,
          name,
          homeOffice,
          billTo,
          billTos,
          shipTo,
          shipTos,
          userRole,
          accountDescription,
          issuer,
          app
        };
      } else if (
        authResult.idTokenPayload.name &&
        authResult.idTokenPayload.email
      ) {
        const { name, email } = authResult.idTokenPayload;
        this.userProfile = { name, email, app: 'LaunchPad' };
      }
    }

    console.info(`Selected application: ${this.userProfile.app}`)

    if (this.userProfile.app && this.userProfile.app === 'DealerProfile') {
      console.info(`Redirecting to: ${config.dealerProfile.name} [${config.dealerProfile.url}]`)
      window.location.replace(config.dealerProfile.url);
    } else if (this.userProfile.app && this.userProfile.app === 'AllPrefs') {
      console.info(`Redirecting to: ${config.coreProfile.name} [${config.coreProfile.url}${config.coreProfile.allPrefs}]`)
      window.location.replace(config.coreProfile.url + config.coreProfile.allPrefs);
    } else if (this.userProfile.app && this.userProfile.app === 'BulkOrderPrefs') {
      console.info(`Redirecting to: ${config.coreProfile.name} [${config.coreProfile.url}${config.coreProfile.bulkOrderPrefs}]`)
      window.location.replace(config.coreProfile.url + config.coreProfile.bulkOrderPrefs);
    } else if (this.userProfile.app && this.userProfile.app === 'ONCallPrefs') {
      console.info(`Redirecting to: ${config.coreProfile.name} [${config.coreProfile.url}${config.coreProfile.onCallPrefs}]`)
      window.location.replace(config.coreProfile.url + config.coreProfile.onCallPrefs);
    } else if (this.userProfile.app && this.userProfile.app === 'oncalldr') {
      console.info(`Redirecting to: ${config.oncalldr.name} [${config.oncalldr.url}]`)
      window.location.replace(config.coreProfile.url + config.coreProfile.onCallPrefs);
    } else if (this.userProfile.app && this.userProfile.app === 'TireServiceManual') {
      console.info(`Redirecting to: ${config.dtsm.name} [${config.dtsm.url}]`)
      window.location.replace(config.dtsm.url);
    } else if (this.userProfile.app && this.userProfile.app === 'LiveTrack') {
      console.info(`Redirecting to: ${config.liveTrack.name} [${config.liveTrack.url}]`)
      window.location.replace(config.liveTrack.url);
    } else if (this.userProfile.app && this.userProfile.app === 'MaestroCentral') {
      console.info(`Redirecting to: ${config.maestroCentral.name} [${config.maestroCentral.url}]`)
      window.location.replace(config.maestroCentral.url);
    } else if (this.userProfile.app && this.userProfile.app === 'FixPix') {
      console.info(`Redirecting to: ${config.fixPix.name} [${config.fixPix.url}]`)
      window.location.replace(config.fixPix.url);
    } else {
      // navigate to the home route
      history.replace('/');
    }
  }

  renewSession() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) {
          return reject(err);
        }

        this.setSession(authResult);
        resolve();
      });
    });
  }

  logoutLocal() {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    // Remove user profile
    this.userProfile = null;

    // navigate to the home route
    history.replace('/');
  }

  logout() {
    this.logoutLocal();

    if (config.auth.logoutUri)
      this.auth0.logout({ returnTo: config.auth.logoutUri });
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.expiresAt;
    return new Date().getTime() < expiresAt;
  }
}

export const auth = new Auth();

export class AuthProvider extends React.Component {
  constructor(props) {
    super(props);
    this.auth = auth;
  }

  render() {
    const { children } = this.props;

    return <AuthContext.Provider value={this}>{children}</AuthContext.Provider>;
  }
}

export const AuthConsumer = AuthContext.Consumer;
