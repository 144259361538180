// configureStore.js
import history from "../history"
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import promiseMiddleware from "redux-promise-middleware";
import thunk from "redux-thunk";
import combineReducers from "../reducers/combinedReducers";
 
const rootReducers = combineReducers(history);
// const connectedRouter = connectRouter(history, rootReducers)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(preloadedState = {}) {

  const middleware = [
    routerMiddleware(history),
    promiseMiddleware,
    thunk,
  ]

  const enhancers = [
    applyMiddleware(...middleware),
  ]

  const store = createStore(
    rootReducers,
    preloadedState,
    composeEnhancers(...enhancers)
  );

  window.store = store;
    
  return store
}

