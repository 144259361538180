import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AuthProvider } from './Auth';
import { ConnectedRouter } from 'connected-react-router';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@michelin/acid-components';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import history from './history';

const storeConfig = configureStore({});
// const store = storeConfig.store;

ReactDOM.render(
  <Provider store={storeConfig}>
    <AuthProvider>
      <ConnectedRouter history={history}>
        <MuiThemeProvider
          theme={createTheme({ palette: { type: 'michelinLight' } })}
        >
          <App />
        </MuiThemeProvider>
      </ConnectedRouter>
    </AuthProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
