import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
// import login from './login'
// import appState from './appState'

// import startup from './startup';
// import i18n from './i18n';

export default (history) => combineReducers({
    router: connectRouter(history),
    // appState,
    // login,
})

