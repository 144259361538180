import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';

const styles = theme => ({
  link: {
    margin: theme.spacing.unit,
    cursor: "default",
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
  },
});

class PrivacyPolicyDialogFrench extends React.Component {
  state = {
    open: false,
    fullWidth: true,
    maxWidth: 'xl',
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Link color="primary" onClick={this.handleClickOpen} className={classes.link}>
          Politique de confidentialité
        </Link>
        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="privacy-policy"
        >
          <DialogTitle id="max-width-dialog-title">Politique de confidentialité</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="h5" gutterBottom>
                PRATIQUES DE MICHELIN RELATIVES À LA PROTECTION DES DONNÉES PERSONNELLES CONFIDENTIELLES
              </Typography>
              <Typography variant="body1" gutterBottom>
                Michelin Amérique du Nord (Canada) inc. («MAN(C)I ») respecte la vie privée de ses
                visiteurs et utilisateurs de l’ensemble de ses sites Internet. Ce document décrit les
                pratiques adoptées par Michelin concernant les données personnelles, y compris les
                circonstances dans lesquelles elles peuvent être recueillies, les fins pour lesquelles
                elles peuvent être utilisées, et les personnes auxquelles elles sont destinées. Chaque
                site Internet Michelin a un objectif particulier et possède des caractéristiques qui
                lui sont propres. Si des acquisitions supplémentaires de données personnelles sont
                nécessaires pour un site Internet Michelin en particulier, vous en serez informé sur le site.
              </Typography>

              <Typography variant="h5" gutterBottom>
                RENSEIGNEMENTS PERSONNELS
              </Typography>
              <Typography variant="body1" gutterBottom>
                De manière générale, la plupart des sites Internet Michelin ne vous obligent pas à
                divulguer vos renseignements personnels. Il se peut que Michelin vous demande de
                fournir vos données personnelles afin de mieux répondre à vos besoins, par exemple
                pour établir une correspondance, pour répondre à une demande ou toute autre circonstance
                similaire. Les données personnelles peuvent inclure votre nom, votre adresse, votre
                numéro de téléphone, votre adresse électronique et autres informations spécifiques,
                telles qu'un nom d'utilisateur, un mot de passe ou des informations sur votre compte.
              </Typography>

              <Typography variant="h5" gutterBottom>
                COLLECTE DES RENSEIGNEMENTS PERSONNELS
              </Typography>
              <Typography variant="body1" gutterBottom>
                Michelin n'utilise vos données personnelles que dans les limites strictes des objectifs
                pour lesquels elles ont été recueillies. Par exemple, Michelin peut utiliser vos données
                personnelles pour :
              </Typography>

              <Typography variant="h5" gutterBottom>
                UTILISATION DES RENSEIGNEMENTS PERSONNELS
              </Typography>
              <Typography variant="body1" gutterBottom>
                Nous utilisons des renseignements permettant d'identifier une personne ou non pour
                l'administration du site et dans le but d'effectuer les services pour lesquels ces
                données ont été recueillies.
                <ul>
                  <li>Vous fournir des informations sur ses produits et services;</li>
                  <li>Effectuer et suivre vos transactions;</li>
                  <li>Vous permettre de prendre part aux aspects interactifs de ses sites;</li>
                  <li>Communiquer, développer et échanger avec vous.</li>
                  <li>Michelin s'engage à ne pas utiliser vos données personnelles sans votre consentement préalable.</li>
                </ul>
              </Typography>

              <Typography variant="h6" gutterBottom>
                Les destinataires de vos renseignements personnels
              </Typography>
              <Typography variant="body1" gutterBottom>
                L'usage de vos données personnelles est exclusivement réservé aux personnes qui
                doivent en prendre connaissance pour répondre à des besoins d'affaires. Michelin
                peut partager vos données personnelles avec des fournisseurs tiers ou des fournisseurs
                de services. Ces fournisseurs et fournisseurs de services sont contractuellement tenus
                de garder vos données personnelles confidentielles et sécurisées, et ils sont tenus
                d'utiliser vos données personnelles uniquement aux fins des services qu'ils doivent fournir.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Michelin ne vendra ni ne louera vos données personnelles à des tiers sans votre
                consentement, sauf en vertu d'une ordonnance du tribunal, pour se conformer à la
                loi applicable ou pour protéger les droits et les produits de Michelin.
              </Typography>

              <Typography variant="h5" gutterBottom>
                TEMPS DE CONSERVATION
              </Typography>
              <Typography variant="body1" gutterBottom>
                Michelin ne conservera pas vos données personnelles au-delà de la durée nécessaire pour
                atteindre les objectifs pour lesquels elles ont été recueillies ou de la durée requise
                par la loi.
              </Typography>

              <Typography variant="h5" gutterBottom>
                DROITS D'ACCÈS, DE MODIFICATION OU D'OPPOSITION
              </Typography>
              <Typography variant="body1" gutterBottom>
                Vous pouvez demander à Michelin d'accéder aux données personnelles qu'elle a en sa
                possession, et vous pouvez modifier ces données si elles ne sont pas exactes. Vous
                avez le droit d'interdire à Michelin de collecter ou de divulguer vos données personnelles.
                En ce qui concerne la prospection commerciale, vous pouvez utiliser votre droit d'opposition
                sans aucune justification. Pour exercer l'un de ces droits, veuillez vous adresser au
                webmestre du site Internet concerné et/ou au responsable de la protection de la vie privée.
              </Typography>

              <Typography variant="h5" gutterBottom>
                SÉCURITÉ DES RENSEIGNEMENTS
              </Typography>
              <Typography variant="body1" gutterBottom>
                Michelin a mis en place des mesures pour assurer la confidentialité, la sécurité et
                l'intégrité de ses systèmes contenant vos données personnelles. L'accès aux données
                personnelles est limité à ceux qui en ont besoin pour des raisons d'affaires et ils
                sont tenus d'observer ces règles.
              </Typography >
              <Typography variant="body1" gutterBottom>
                Sur les sites commerciaux de Michelin, la collecte des coordonnées bancaires sera
                effectuée dans le cadre de processus sécurisés des paiements. Ces mesures peuvent
                consister en un cryptage lors de la collecte ou du transfert de données confidentielles.
                Michelin veille à ce que vos données personnelles ne soient pas modifiées, endommagées
                ou mal utilisées.
              </Typography>

              <Typography variant="h5" gutterBottom>
                TÉMOINS (cookies)
              </Typography>
              <Typography variant="body1" gutterBottom>
                Michelin collecte des données relatives à la façon dont vous utilisez nos sites
                Internet pour améliorer son service aux visiteurs et aux utilisateurs. Pour ce
                faire, elle utilise des témoins (cookies), qui sont des fichiers que votre navigateur
                Internet place sur votre disque dur lorsque vous visitez un site. Les témoins ne
                permettent pas de vous identifier personnellement. Les données enregistrées peuvent
                être des informations telles que les pages consultées, la date et l'heure de cette
                consultation et d'autres informations de suivi.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Vous pouvez paramétrer votre navigateur afin qu'il vous informe de la présence de
                témoins et vous offre l'option de les accepter ou non. Vous pouvez également
                paramétrer votre navigateur pour décontaminer les témoins.
              </Typography>

              <Typography variant="h5" gutterBottom>
                AVIS SUR LES MARQUES DE COMMERCE ET LES DROITS D'AUTEUR
              </Typography>
              <Typography variant="body1" gutterBottom>
                Michelin est une société internationale qui possède des bases de données dans les différents
                pays où elle opère. Elle peut transférer vos données personnelles au sein de l'entreprise
                ou à ses partenaires externes situés en dehors de votre pays d'origine.
              </Typography >
              <Typography variant="body1" gutterBottom>
                Étant donné que le niveau de protection des données n'est pas homogène partout dans
                le monde, nous ne transmettons pas vos données personnelles à des entreprises tierces
                en dehors de l'Union européenne, à moins que ces entreprises ne proposent contractuellement
                une protection similaire à celle que Michelin vous offre.
              </Typography>

              <Typography variant="h5" gutterBottom>
                MINEURS
              </Typography>
              <Typography variant="body1" gutterBottom>
                Les sites Internet Michelin ne sont généralement pas destinés aux mineurs (moins de
                dix-huit (18) ans). Si vous êtes mineur, nous vous encourageons à ne pas transmettre
                vos informations personnelles sans l'autorisation de vos parents.
              </Typography>

              <Typography variant="h6" gutterBottom>
                Liens vers des sites web tiers
              </Typography>
              <Typography variant="body1" gutterBottom>
                Les sites Internet Michelin peuvent inclure des hyperliens vers des sites tiers. Michelin
                ne contrôle pas ces sites et ne peut donc pas être tenue responsable de leurs pratiques
                relatives à la confidentialité et à la sécurité de vos données personnelles. Nous vous
                invitons à consulter les politiques de confidentialité de ces sites avant de les utiliser
                ou de leur fournir vos données personnelles.
              </Typography>

              <Typography variant="h5" gutterBottom>
                AVIS SUR LES MARQUES DE COMMERCE ET LES DROITS D'AUTEUR
              </Typography>
              <Typography variant="body1" gutterBottom>
                Veuillez consulter nos conditions d'utilisation pour plus d'information..
              </Typography>

              <Typography variant="h5" gutterBottom>
                CONSENTEMENT À CETTE POLITIQUE DE CONFIDENTIALITÉ
              </Typography>
              <Typography variant="body1" gutterBottom>
                En utilisant ce site, vous signalez votre consentement à cette politique de confidentialité.
                Si vous n'êtes pas d'accord avec un terme de cette politique, n'utilisez pas ce site ou ne
                donnez aucune information permettant de vous identifier.
              </Typography>

              <Typography variant="h5" gutterBottom>
                AMENDEMENTS
              </Typography>
              <Typography variant="body1" gutterBottom>
                Cette politique est entrée en vigueur le 15 avril 2011. Toute modification sera affichée sur cette
                URL et sera effective lorsque vous l'afficherez. Ainsi, vérifiez régulièrement cette page.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

PrivacyPolicyDialogFrench.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrivacyPolicyDialogFrench);