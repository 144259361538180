import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Routes from "../routes/routes";

const styles = () => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  }
});

class LayoutComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Routes />
        </main>
      </div>
    );
  }
}

LayoutComponent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LayoutComponent);
