import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  AppBar,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  ExitToApp as ExitToAppIcon,
  AccountBox as AccountBoxIcon,
  ContactMail as ContactMailIcon,
  Check as CheckIcon,
} from '@material-ui/icons';
import logo from '@michelin/acid-components/assets/images/logos/Michelin_C_S_WhiteBG_RGB_0621-01.svg';
import english from '../../assets/images/EN.svg';
import french from '../../assets/images/FR.svg';
import { AuthConsumer } from '../../Auth';
import xlate from '../../i18n';
import config from '../../config';
import clsx from 'clsx';

const styles = (theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  appBar: {
    position: 'relative',
    padding: '6px 24px',
    backgroundColor: '#fff',
  },
  toolbarTitle: {
    flex: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  heroContent: {
    // maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 4}px`,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
    // backgroundColor: "#FCE500",
    // color: "#27509B",
    color: '#7ad95f',
    fontSize: '2em',
    cursor: 'pointer',
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  footer: {
    marginTop: theme.spacing.unit * 8,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit * 6}px 0`,
  },
  logo: {
    height: 60,
  },
  footerLogo: {
    height: 20,
  },
  icon: {
    height: 24,
  },
  textHeader: {
    // color: "#FFFFFF"
  },
  buttonLaunch: {
    // backgroundColor: "#27509B",
    backgroundColor: '#7ad95f',
    color: '#FFFFFF',
    textDecoration: 'none',
    width: '100%',
    borderRadius: '3px',
    '&:hover': {
      // color: "#27509B",
      color: '#7ad95f',
      backgroundColor: '#FFFFFF',
      textDecoration: 'none',
    },
  },
  avatar: {
    // backgroundColor: "#27509B"
    backgroundColor: '#7ad95f',
  },
  username: {
    alignSelf: 'center',
    textAlign: 'right',
    marginRight: '18px',
  },
  loginButton: {
    textTransform: 'none',
    width: '200px',
  },
  menu: {},
  listItemIcon: {
    marginRight: 0,
  },
  flex: {
    display: 'flex',
  },
  flexGrow: {
    flex: 1,
  },
  pointer: {
    cursor: 'pointer',
  },
});

function AppBarAuthComponent(classes) {
  return (
    <AuthConsumer>
      {(props) => (
        <AppBarComponent
          userProfile={props.auth.userProfile}
          {...props}
          {...classes}
        />
      )}
    </AuthConsumer>
  );
}

class AppBarComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  onLoginClick = () => {
    this.props.auth.login();

    this.handleClose();
  };

  onB2BLoginClick = () => {
    window.location = config.login;

    this.handleClose();
  };

  onLogoutClick = () => {
    this.props.auth.logout();

    this.handleClose();
  };

  handleOpen = (anchorEl) => {
    this.setState({ ...this.state, anchorEl });
  };

  handleClose = () => {
    this.setState({ ...this.state, anchorEl: null });
  };

  setLanguage = (language) => {
    this.props.setCurrentLanguage(language);

    this.setState({ ...this.state, anchorEl: null });
  };

  render() {
    const { classes, userProfile, language } = this.props;
    const { anchorEl } = this.state;

    const isLoggedIn = userProfile ? true : false;
    const username =
      userProfile && userProfile.name ? userProfile.name : 'Click to login';

    return (
      <AppBar position='static' color='default' className={classes.appBar}>
        <Grid container direction='row' alignItems='center' justify='center'>
          <Grid item md={3} xs={12}>
            <img alt='michelin' src={logo} className={classes.logo} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography
              component='h1'
              variant='h4'
              align='center'
              color='textPrimary'
              className={classes.textHeader}
            >
              {xlate('home.welcome')}
            </Typography>
          </Grid>

          <Grid item md={3} className={classes.flex}>
            <div className={classes.flexGrow} />
            <div onClick={(event) => this.handleOpen(event.currentTarget)}>
              <nobr className={clsx(classes.username, classes.pointer)}>
                {username}
              </nobr>

              <IconButton
                aria-controls='menu-login'
                aria-haspopup='true'
                className={classes.menu}
              >
                <MenuIcon />
              </IconButton>
            </div>

            <Menu
              id='menu-login'
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem onClick={() => this.setLanguage('en-US')}>
                <ListItemIcon className={classes.listItemIcon}>
                  <img alt='english' src={english} className={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={xlate('home.english')} />
                {language === 'en-US' && (
                  <ListItemSecondaryAction>
                    <CheckIcon />
                  </ListItemSecondaryAction>
                )}
              </MenuItem>
              <MenuItem onClick={() => this.setLanguage('fr-CA')}>
                <ListItemIcon className={classes.listItemIcon}>
                  <img alt='french' src={french} className={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={xlate('home.french')} />
                {language === 'fr-CA' && (
                  <ListItemSecondaryAction>
                    <CheckIcon />
                  </ListItemSecondaryAction>
                )}
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => this.onB2BLoginClick()}
                disabled={isLoggedIn}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <AccountBoxIcon />
                </ListItemIcon>
                <ListItemText primary={xlate('home.login_b2b')} />
              </MenuItem>
              <MenuItem
                onClick={() => this.onLoginClick()}
                disabled={isLoggedIn}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <ContactMailIcon />
                </ListItemIcon>
                <ListItemText primary={xlate('home.login_email')} />
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => this.onLogoutClick()}
                disabled={!isLoggedIn}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary={xlate('home.logout')} />
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </AppBar>
    );
  }
}

AppBarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

AppBarAuthComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppBarAuthComponent);
