import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';

const styles = theme => ({
  link: {
    margin: theme.spacing.unit,
    cursor: "default",
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
  },
});

class TermsOfUseDialogFrench extends React.Component {
  state = {
    open: false,
    fullWidth: true,
    maxWidth: 'xl',
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Link color="primary" onClick={this.handleClickOpen} className={classes.link}>
          Conditions d'utilisation
        </Link>
        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="privacy-policy"
        >
          <DialogTitle id="max-width-dialog-title">Conditions d'utilisation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="body1" gutterBottom>
                Merci de visiter ce site web Michelin (« Site »). Veuillez lire attentivement les conditions d'utilisation
                avant d'utiliser ce Site. En utilisant ce Site, vous acceptez ces conditions d'utilisation. Si vous n'acceptez pas
                ces conditions d'utilisation, n'utilisez pas ce Site. Nous pouvons réviser ces conditions d'utilisation à tout moment
                et sans préavis. Vérifiez la date d'entrée en vigueur des amendements au bas de cette page.
              </Typography>

              <Typography variant="h5" gutterBottom>PUBLIC CIBLÉ ET UTILISATION</Typography>
              <Typography variant="body1" gutterBottom>
                Michelin Amérique du Nord (Canada) inc. (« MAN(C)I ») fournit ce Site à l'usage licite de ses clients, employés et du
                grand public en général, ayant plus de 13 ans et résidant au Canada. MAN(C)I ne garantit pas que le matériel et les
                produits compris dans ce Site sont appropriés ou disponibles dans d'autres localisations. Les personnes accédant au site
                depuis d'autres localisations le font à leurs propres risques et sont tenues de respecter les lois locales applicables.
              </Typography>

              <Typography variant="h5" gutterBottom>UTILISATION DU CONTENU DU SITE WEB</Typography>
              <Typography variant="body1" gutterBottom>
                Les marques déposées et les matériaux protégés par droits d'auteur de Michelin sont contenus sur ce Site, y compris les noms
                de marques de commerce, les logos, les photographies, les documents et les logiciels. Vous n'êtes autorisé à utiliser ce
                matériel qu'à des fins personnelles et non commerciales, y compris le téléchargement et l'impression de documents, à
                condition que cet avis d'autorisation de droits d'auteur soit inclus dans toutes ces copies imprimées. Aucun droit ni licence
                sur le matériel ou une partie du matériel ne doit être accordé ou implicite et vous ne pouvez pas modifier le matériel de
                quelque manière que ce soit sans l'autorisation écrite préalable de Michelin Amérique du Nord inc. Nous appliquons nos droits
                de propriété intellectuelle dans toute la mesure de la loi.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Le Bonhomme Michelin est une marque déposée détenue par Michelin Amérique du Nord inc.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Si vous avez l'intention d'utiliser du matériel de ce Site, quel qu'il soit, à titre autre que privé et non commercial, vous
                êtes tenu d'obtenir préalablement l'autorisation écrite de MAN(C)I. Pour plus d'information, veuillez consulter notre énoncé
                sur les droits d'auteur et les marques de commerce.
              </Typography>

              <Typography variant="h5" gutterBottom>LIENS VERS DES SITES WEB DE TIERS</Typography>
              <Typography variant="body1" gutterBottom>
                Ce Site peut fournir des liens vers des sites Web ou des ressources tiers/tierces. Ces liens sont fournis uniquement pour votre
                commodité. MAN(C)I n'a aucun contrôle sur ces sites et ces ressources, et ne représente ni ne s'engage d'aucune manière en ce
                qui a trait à ces sites Web, ni pour l'exactitude des informations, du matériel, des publicités, des produits et des services,
                ni pour les résultats obtenus grâce à leur utilisation. Si vous décidez d'accéder à ces sites tiers, vous acceptez de le faire
                entièrement à vos risques.
              </Typography>

              <Typography variant="h5" gutterBottom>CLAUSE DE NON-RESPONSABILITÉ DE GARANTIE ET LIMITATIONS DES RECOURS</Typography>
              <Typography variant="body1" gutterBottom>
                Nous nous efforçons de fournir des informations précises et à jour sur ce Site. Toutefois, la totalité du Site vous est fournie
                sur une base « SANS GARANTIE » et l'utilisation de ce Site est entièrement à vos risques. Nous ne donnons aucune garantie ni ne
                faisons aucune déclaration, formelles ou tacites, concernant ce Site ou son fonctionnement. <em>NOUS DÉCLINONS EXPRESSÉMENT TOUTES
                  LES GARANTIES, FORMELLES OU TACITES, Y COMPRIS, MAIS SANS S'Y LIMITER, CELLE DE LA QUALITÉ MARCHANDE OU DE L'APTITUDE DE CE SITE
                  POUR UN USAGE PARTICULIER.</em> En aucun cas, y compris pour sa propre négligence, mais sans s'y limiter, MAN(C)I ne pourra être
                tenue responsable des dommages, y compris les dommages indirects, dérivant de l'utilisation de ce Site ou de tout empêchement à
                l'utiliser. Les informations fournies sur ce Site sont de nature générale. L'usage des pneus peut être affecté par des facteurs tels
                que le lieu géographique, l'âge et l'état du véhicule, ainsi que les habitudes personnelles de conduite. Veuillez consulter votre
                détaillant ou représentant autorisé avant toute décision d'achat.
              </Typography>

              <Typography variant="h5" gutterBottom>NOTRE ENGAGEMENT DE CONFIDENTIALITÉ</Typography>
              <Typography variant="body1" gutterBottom>
                Michelin s'engage à protéger la confidentialité des informations personnelles permettant de vous identifier. Veuillez consulter
                notre politique de confidentialité des États Unis.
              </Typography>

              <Typography variant="h5" gutterBottom>LÉGAL</Typography>
              <Typography variant="body1" gutterBottom>
                L'utilisation de ce Site et ses conditions d'utilisation sera régie et interprétée conformément aux lois canadiennes sans donner
                effet à aucun principe de conflit de lois.
              </Typography>

              <Typography variant="h5" gutterBottom>MODIFICATIONS</Typography>
              <Typography variant="body1" gutterBottom>
                Ces conditions d'utilisation sont entrées en vigueur le 21 mai 2004. Toute modification sera affichée à cette URL et sera effective dès sa publication.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Accepter
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

TermsOfUseDialogFrench.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TermsOfUseDialogFrench);