import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';

const styles = theme => ({
	link: {
		margin: theme.spacing.unit,
		cursor: "default",
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		margin: 'auto',
		width: 'fit-content',
	},
	formControl: {
		marginTop: theme.spacing.unit * 2,
		minWidth: 120,
	},
	formControlLabel: {
		marginTop: theme.spacing.unit,
	},
});

class PrivacyPolicyDialog extends React.Component {
	state = {
		open: false,
		fullWidth: true,
		maxWidth: 'xl',
	};

	handleClickOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	render() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				<Link color="primary" onClick={this.handleClickOpen} className={classes.link}>
					Privacy Policy
        </Link>
				<Dialog
					fullWidth={this.state.fullWidth}
					maxWidth={this.state.maxWidth}
					open={this.state.open}
					onClose={this.handleClose}
					aria-labelledby="privacy-policy"
				>
					<DialogTitle id="max-width-dialog-title">Privacy Policy</DialogTitle>
					<DialogContent>
						{/* <DialogContentText> */}
						<Typography variant="h5" gutterBottom>
							Michelin Practices Related to the Protection of Confidential
							Personal Data
          				</Typography>
						<Typography variant="body1" gutterBottom>
							Michelin North America, Inc. ("Michelin"), respects the private
							lives of the visitors and users of its Internet sites. This document
							describes the practices adopted by Michelin concerning personal
							data, including the circumstances in which it may be collected, the
							purposes for which it may be used, and the people for which it is
							intended. Each Michelin Internet site has a particular aim and has
							characteristics which are unique to it. If additional personal data
							acquisitions are necessary for a Michelin Internet site in
							particular, you will be so informed on the site.
          				</Typography>

						<Typography variant="h5" gutterBottom>Personal Data</Typography>
						<Typography variant="body1" gutterBottom>
							Generally, most Michelin Internet sites do not require you to
							disclose your personal data. It may be that Michelin requires you to
							provide your personal data in order to better meet your needs, for
							example, to establish a correspondence, to respond to a request, and
							other similar circumstances. "Personal data" may include your name,
							addresses, telephone number, email addresses, and other specific
							information such as a user name, password, or account information.
          				</Typography>

						<Typography variant="h5" gutterBottom>Automatically Collected Data</Typography>
						<Typography variant="body1" gutterBottom>
							We automatically collect the following information about your use of
							our sites through cookies, web beacons, and other technologies: your
							domain name; your browser type and operating system; web pages you
							view; links you click; your IP address; the length of time you visit
							our sites; and the referring URL, or the webpage that led you to our
							sites. Please see the section "Cookies and Other Tracking
							Mechanisms" below for more information.
          				</Typography>

						<Typography variant="h5" gutterBottom>Collection of Personal Data</Typography>
						<Typography variant="body1" gutterBottom>
							Michelin will collect your personal data with precise, explicit, and
							legitimate goals. It will not treat this data in a way that is
							incompatible with these goals. Michelin is committed to collecting
							only the information that is adequate, relevant, and non-excessive,
							taking into consideration the goal for which it is collected.
							Michelin will take all reasonable measures to ensure that the
							personal data collected is accurate.
          				</Typography>

						<Typography variant="h5" gutterBottom>Use of Personal Data</Typography>
						<Typography variant="body1" gutterBottom>
							Michelin uses your personal data within the strict limits of the
							goals for which it was collected. As an example, Michelin may use
							your personal data to:
						</Typography>
						<ul>
							<li>Provide you with information on its products and services;</li>
							<li>Carry out and follow your transactions;</li>
							<li>Allow you to take part in the interactive aspects of its sites; and</li>
							<li>Communicate, develop, and trade with you.</li>
						</ul>
						<Typography variant="body1" gutterBottom>
							Michelin is committed to not using your personal data without your
							prior consent.
          				</Typography>

						<Typography variant="h5" gutterBottom>Recipients of your personal data</Typography>
						<Typography variant="body1" gutterBottom>
							The recipients of your personal data are limited exclusively to the
							people who have a business need to know. Michelin may share your
							personal data with third party suppliers or service providers. These
							suppliers and service providers are contractually obligated to keep
							your personal data confidential and secure, and they are required to
							use your personal data only for the needs for the services that were
							entrusted to them. Michelin will not sell or rent your personal data
							to third parties without your consent, except pursuant to a court
							order, to comply with applicable law, or to protect the rights and
							the goods of Michelin.
          				</Typography>

						<Typography variant="h5" gutterBottom>Shelf Life</Typography>
						<Typography variant="body1" gutterBottom>
							Michelin will not preserve your personal data any longer than it is
							necessary to achieve the goals for which it was collected or for the
							time period required by law.
          				</Typography>

						<Typography variant="h5" gutterBottom>Right of access, Modification, or Opposition</Typography>
						<Typography variant="body1" gutterBottom>
							You can ask Michelin for access to your personal data in its
							possession, and you may modify this data if it is not accurate. You
							have the right to prohibit Michelin from collecting or disclosing
							your personal data. As regards commercial prospection, you can use
							your right of opposition without any justification. For the exercise
							of these rights, please notify the webmaster of the Internet site
							concerned and/or the Privacy Officer.
          				</Typography>

						<Typography variant="h5" gutterBottom>Data Security</Typography>
						<Typography variant="body1" gutterBottom>
							Michelin has established measures to ensure the confidentiality,
							safety and integrity of its systems containing your personal data.
							The access to the personal data is restricted to those with a
							business need to know and who are required to observe these rules.
							On Michelin's commercial sites, the collection of the banking
							coordinates will be carried out within the framework of effective
							processes of security of the payments. These measurements can
							consist of encryption during the collection or transfer of
							confidential data. Michelin takes care to ensure that your personal
							data is not altered, damaged or misused.
          				</Typography>

						<Typography variant="h5" gutterBottom>Cookies</Typography>
						<Typography variant="body1" gutterBottom>
							Michelin and our third party service providers collect data relating
							to how you use our Internet sites to improve our service to visitors
							and users of these sites through the use of cookies.
          				</Typography>
						<Typography variant="body1" gutterBottom>
							You can parameterize your navigator so that it informs you of the
							presence of cookies and provides you with the option of accepting
							the cookies. You can also parameterize your navigator to
							decontaminate the cookies.
          				</Typography>
						<Typography component="span" variant="body1" gutterBottom>
							<span style={{ fontWeight: 600 }}>Cookies.</span> Cookies are
							alphanumeric identifiers that we transfer to your computer's hard
							drive through your web browser for record-keeping purposes. Some
							cookies allow us to make it easier for you to navigate our site,
							while others are used to enable a faster log-in process or to allow
							us to track your activities at our sites. There are two types of
							cookies: session and persistent cookies.
						</Typography>
						<ul>
							<li>
								<span style={{ fontWeight: 500, textDecoration: 'underline' }}>
									Session Cookies.
								</span>{' '}
								Session cookies exist only during an online session. They
								disappear from your computer when you close your browser or turn
								off your computer. We use session cookies to allow our systems to
								uniquely identify you during a session or while you are logged
								into the Site. This allows us to process your online transactions
								and requests and verify your identity, after you have logged in,
								as you move through our sites.
							</li>
							<li>
								<span style={{ fontWeight: 500, textDecoration: 'underline' }}>
									Persistent Cookies.
								</span>{' '}
								Persistent cookies remain on your computer after you have closed
								your browser or turned off your computer. We use persistent
								cookies to track aggregate and statistical information about user
								activity.
							</li>
						</ul>

						<Typography variant="body1" gutterBottom>
							<span style={{ fontWeight: 600 }}>Disabling Cookies.</span> Most web
								browsers automatically accept cookies, but if you prefer, you can
								edit your browser options to block them in the future. The Help
								portion of the toolbar on most browsers will tell you how to prevent
								your computer from accepting new cookies, how to have the browser
								notify you when you receive a new cookie, or how to disable cookies
								altogether. Visitors to our sites who disable cookies will be able
								to browse certain areas of the sites, but some features may not
								function.
          				</Typography>
						<Typography variant="body1" gutterBottom>
							<span style={{ fontWeight: 600 }}>Local Storage Objects.</span> We
								may use Flash Local Storage Objects ("Flash LSOs") to store your
								site preferences and to personalize your visit. Flash LSOs are
								different from browser cookies because of the amount and type of
								data stored. Typically, you cannot control, delete, or disable the
								acceptance of Flash LSOs through your web browser. For more
								information on Flash LSOs, or to learn how to manage your settings
								for Flash LSOs, go to the Adobe Flash Player Help Page, choose
								"Global Storage Settings Panel" and follow the instructions. To see
								the Flash LSOs currently on your computer, choose "Website Storage
								Settings Panel" and follow the instructions to review and, if you
								choose, to delete any specific Flash LSO.
          				</Typography>
						<Typography variant="body1" gutterBottom>
							<span style={{ fontWeight: 600 }}>
								Clear GIFs, pixel tags and other technologies.
            				</span>{' '}
								Clear GIFs are tiny graphics with a unique identifier, similar in
								function to cookies. In contrast to cookies, which are stored on
								your computer's hard drive, clear GIFs are embedded invisibly on web
								pages. We may use clear GIFs (a.k.a. web beacons, web bugs or pixel
								tags), in connection with our sites to, among other things, track
								the activities of site visitors, help us manage content, and compile
								statistics about site usage. We and our third party service
								providers also use clear GIFs in HTML e-mails to our customers, to
								help us track e-mail response rates, identify when our e-mails are
								viewed, and track whether our e-mails are forwarded.
          				</Typography>
						<Typography variant="body1" gutterBottom>
							<span style={{ fontWeight: 600 }}>Third Party Analytics.</span> We
								use automated devices and applications to evaluate usage of our
								sites, including but not limited to Google Analytics. To learn more
								about how Google Analytics collects and processes data, visit: "How
								Google uses data when you use our partners' sites or apps", (located
								at www.google.com/policies/privacy/partners/, or any other URL
								Google may provide from time to time). We also may use other
								analytic means to evaluate our sites. We use these tools to help us
								improve our sites, performance and user experiences. These entities
								may use cookies and other tracking technologies to perform their
								services.
          				</Typography>
						<Typography variant="body1" gutterBottom>
							<span style={{ fontWeight: 600 }}>Do Not Track.</span> Currently,
								our systems do not recognize browser "do-not-track" requests. You
								may, however, disable certain tracking as discussed in this section
								(e.g., by disabling cookies); you also may opt-out of targeted
								advertising by following the instructions in the Third Party Ad
								Network section.
          				</Typography>
						<Typography variant="body1" gutterBottom>
							<span style={{ fontWeight: 600 }}>Third Party Ad Networks.</span> We
								use third parties such as network advertisers to serve
								advertisements on our sites and on third-party websites or other
								media (e.g., social networking platforms). This enables us and these
								third parties to target advertisements to you for products and
								services in which you might be interested. Third-party ad network
								providers, advertisers, sponsors and/or traffic measurement services
								may use cookies, JavaScript, web beacons (including clear GIFs),
								Flash LSOs and other tracking technologies to measure the
								effectiveness of their ads and to personalize advertising content to
								you. These third-party cookies and other technologies are governed
								by each third party's specific privacy policy, not this one. We may
								provide these third-party advertisers with information about you.
          				</Typography>
						<Typography variant="body1" gutterBottom>
							Users in the United States may opt out of many third-party ad
							networks. For example, you may go to the Digital Advertising
							Alliance ("DAA") Consumer Choice Page for information about opting
							out of interest-based advertising and their choices regarding having
							information used by DAA companies. You may also go to the Network
							Advertising Initiative ("NAI") Consumer Opt-Out Page for information
							about opting out of interest-based advertising and their choices
							regarding having information used by NAI members.
          				</Typography>
						<Typography variant="body1" gutterBottom>
							Opting out from one or more companies listed on the DAA Consumer
							Choice Page or the NAI Consumer Opt-Out Page will opt you out from
							those companies' delivery of interest-based content or ads to you,
							but it does not mean you will no longer receive any advertising
							through our sites or on other websites. You may continue to receive
							advertisements, for example, based on the particular website that
							you are viewing (i.e., contextually based ads). Also, if your
							browsers are configured to reject cookies when you opt out on the
							DAA or NAI websites, your opt out may not be effective. Additional
							information is available on the DAA's website at www.aboutads.info
							or the NAI's website at www.networkadvertising.org.
          				</Typography>

						<Typography variant="h5" gutterBottom>International Transfers of Personal Data</Typography>
						<Typography variant="body1" gutterBottom>
							Michelin is an international company that has databases in the
							various countries where it operates. Michelin can transfer your
							personal data within the company or to its external partners located
							outside of your home country. Because the level of data protection
							is not homogeneous on a world level, we do not transmit your
							personal data to third party companies outside of the European Union
							unless these companies offer contractually the same or similar level
							of protection of your personal data that Michelin offers. For the
							transfers within Michelin, the company has adopted Binding Corporate
							Rules ("BCRs") to govern the transfers of personal data coming from
							the European Union. If you click on "BCRs," you will find a copy of
							the rules.
          				</Typography>

						<Typography variant="h5" gutterBottom>Interactive Services</Typography>
						<Typography variant="body1" gutterBottom>
							Certain Michelin Internet sites contain interactive functions. You
							can, if you wish it, use these interactive functions by filling a
							form with inscription. This form requires you to disclose certain
							personal data. This personal data is collected with an aim of
							improving the interactive services and of meeting your needs. The
							users registered with these services can have access to the
							information provided at the time of the inscription, and they have
							the right to modify or destroy the information provided at any time.
          				</Typography>

						<Typography variant="h5" gutterBottom>Minors</Typography>
						<Typography variant="body1" gutterBottom>
							Michelin Internet sites are generally not intended for minors (less
							than eighteen (18) years of age). If you are a minor, we encourage
							you not to transmit personal information without the authorization
							of your parents.
          				</Typography>

						<Typography variant="h5" gutterBottom>Links to Third Party Sites</Typography>
						<Typography variant="body1" gutterBottom>
							Michelin Internet sites can include hyperlinks to third party sites.
							Michelin does not control these sites and thus cannot be held
							responsible for their practices relating to the confidentiality and
							security of your personal data. We invite you to review those sites'
							privacy policies before using them or providing them with your
							personal data.
          				</Typography>

						<Typography variant="h5" gutterBottom>Trademark and Copyright Notice</Typography>
						<Typography variant="body1" gutterBottom>
							Please consult our <Link to="/terms">Terms of Use</Link> for further
								information.
          				</Typography>

						<Typography variant="h5" gutterBottom>Consent to This Privacy Policy</Typography>
						<Typography variant="body1" gutterBottom>
							By using this website, you signify your assent to this Privacy
							Policy. If you do not agree with any term in this Policy, please do
							not use this site or submit any personally identifiable information.
          				</Typography>

						<Typography variant="h5" gutterBottom>Amendments</Typography>
						<Typography variant="body1" gutterBottom>
							This Policy became effective on April 15, 2011. Any amendments will
							be posted at this URL and will be effective when posted. Thus,
							please check this page periodically.
          				</Typography>
						{/* </DialogContentText> */}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							Close
            			</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}
}

PrivacyPolicyDialog.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrivacyPolicyDialog);
