import { includes } from 'lodash';

if (!window.location.origin) {
	//ie fix
	window.location.origin =
		window.location.protocol +
		'//' +
		window.location.hostname +
		(window.location.port ? ':' + window.location.port : '');
}

const authDefaults = {
	responseType: "token id_token",
	scope: "openid email profile",
	redirectUri: window.location.origin + '/callback',
	logoutUri: window.location.origin,
	pollingInterval: 10,
}

const allConfigs = {
	localhost: {
		auth: {
			...authDefaults,
			domain: "michelin-local.auth0.com",
			clientID: "4K8043ut7EMVOvVlND3EPelXXIUrwCWU",
		},
		eventViewer: "https://event-viewer.oncall-dev.misp-solutions.com/",
		liveTrack: {
			url: "http://live-track.chaos/",
			target: '_blank',
		},
		techAdmin: "https://oncall-web.oncall-dev.misp-solutions.com/",
		maestroCentral: {
			url: "https://central.integration.misp-solutions.com/",
			target: '_blank',
		},
		esd: "https://esd-request.oncall-dev.misp-solutions.com",
		dealerProfile: {
			url: "https://dealer-profile.core-profile-dev.misp-solutions.com/",
			target: '_blank',
		},
		coreProfile: {
			allPrefs: "all-preferences/all-preferences",
			onCallPrefs: "oncall-tire/oncall-tire",
			bulkOrderPrefs: "bulk-order/bulk-order",
			url: "https://core-profile-dev.misp-solutions.com/",
			target: '_blank',
		},
		dtsm: {
			url: "https://dtsm.core-profile-dev.misp-solutions.com/",
			target: '_blank',
		},
		oncalldr: {
			url: "https://oncalldr.integration.misp-solutions.com/",
			target: '_blank',
		},
		login: "https://dev.michelinb2b.com/B2BIDP/jsp/html/LoginPage.jsp?redirecturl=https://michelin-local.auth0.com/login/callback?connection=B2B-SAML-SP&spid=004",
		fixPix: {
			url: "https://fixpix-migr-stg.services.michelintruck.com/MichelinAdmin/#!login",
			target: '_blank',
		},
	},
	dev: {
		auth: {
			...authDefaults,
			domain: "auth-dev.misp-solutions.com",
			clientID: "7X2lfR9VIY1qlLMVRf5Geu5bNLE68ciV"
		},
		eventViewer: "https://event-viewer.oncall-dev.misp-solutions.com/",
		liveTrack: {
			url: "https://live-track.oncall-dev.misp-solutions.com",
			target: '_blank',
		},
		techAdmin: "https://oncall-web.oncall-dev.misp-solutions.com/",
		maestroCentral: {
			url: "https://central.integration.misp-solutions.com/",
			target: '_blank',
		},
		esd: "https://esd-request.oncall-dev.misp-solutions.com",
		dealerProfile: {
			url: "https://dealer-profile.core-profile-dev.misp-solutions.com/",
			target: '_blank',
		},
		coreProfile: {
			allPrefs: "all-preferences/all-preferences",
			onCallPrefs: "oncall-tire/oncall-tire",
			bulkOrderPrefs: "bulk-order/bulk-order",
			url: "https://core-profile-dev.misp-solutions.com/",
			target: '_blank',
		},
		dtsm: {
			url: "https://dtsm.core-profile-dev.misp-solutions.com/",
			target: '_blank',
		},
		oncalldr: {
			url: "https://oncalldr.integration.misp-solutions.com/",
			target: '_blank',
		},
		login: "https://dev.michelinb2b.com/B2BIDP/jsp/html/LoginPage.jsp?redirecturl=https://auth-dev.misp-solutions.com/login/callback?connection=B2B-SAML-SP&spid=004",
		fixPix: {
			url: "https://fixpix-migr-stg.services.michelintruck.com/MichelinAdmin/#!login",
			target: '_blank',
		},
	},
	test: {
		auth: {
			...authDefaults,
			domain: "auth-test.misp-solutions.com",
			clientID: "XjCOnU2RL4HyL6uNU4m8qBULdUD2Podo"
		},
		eventViewer: "https://event-viewer.oncall-test.misp-solutions.com/",
		liveTrack: {
			url: "https://live-track.oncall-test.misp-solutions.com",
			target: '_blank',
		},
		techAdmin: "https://oncall-web.oncall-test.misp-solutions.com/",
		maestroCentral: {
			url: "https://central.testing.misp-solutions.com/",
			target: '_blank',
		},
		esd: "https://esd-request.oncall-test.misp-solutions.com",
		dealerProfile: {
			url: "https://dealer-profile.core-profile-test.misp-solutions.com/",
			target: '_blank',
		},
		coreProfile: {
			allPrefs: "all-preferences/all-preferences",
			onCallPrefs: "oncall-tire/oncall-tire",
			bulkOrderPrefs: "bulk-order/bulk-order",
			url: "https://core-profile-test.misp-solutions.com/",
			target: '_blank',
		},
		dtsm: {
			url: "https://dtsm.core-profile-test.misp-solutions.com/",
			target: '_blank',
		},
		oncalldr: {
			url: "https://oncalldr.testing.misp-solutions.com/",
			target: '_blank',
		},
		login: "https://test.michelinb2b.com/B2BIDP/jsp/html/LoginPage.jsp?redirecturl=https://auth-test.misp-solutions.com/login/callback?connection=B2B-SAML-SP&spid=004",
		fixPix: {
			url: "https://fixpix-migr-stg.services.michelintruck.com/MichelinAdmin/#!login",
			target: '_blank',
		},
	},
	stg: {
		auth: {
			...authDefaults,
			domain: "michelin-staging.auth0.com",
			clientID: "x3dwLrE5lFbis41Z3vjCNX2vl4yTabGF"
		},
		eventViewer: "https://event-viewer.oncall-test.misp-solutions.com/",
		liveTrack: {
			url: "https://live-track.oncall-staging.misp-solutions.com",
			target: '_blank',
		},
		techAdmin: "https://oncall-web.oncall-staging.misp-solutions.com/",
		maestroCentral: {
			url: "https://central.staging.misp-solutions.com/",
			target: '_blank',
		},
		esd: "https://esd-request.oncall-staging.misp-solutions.com",
		dealerProfile: {
			url: "https://dealer-profile.core-profile-staging.misp-solutions.com/",
			target: '_blank',
		},
		coreProfile: {
			allPrefs: "all-preferences/all-preferences",
			onCallPrefs: "oncall-tire/oncall-tire",
			bulkOrderPrefs: "bulk-order/bulk-order",
			url: "https://core-profile-staging.misp-solutions.com/",
			target: '_blank',
		},
		dtsm: {
			url: "https://dtsm.core-profile-staging.misp-solutions.com/",
			target: '_blank',
		},
		oncalldr: {
			url: "https://oncalldr.staging.misp-solutions.com/",
			target: '_blank',
		},
		login: "https://test.michelinb2b.com/B2BIDP/jsp/html/LoginPage.jsp?redirecturl=https://michelin-test.auth0.com/login/callback?connection=B2B-SAML-SP&spid=004",
		fixPix: {
			url: "https://fixpix-migr-stg.services.michelintruck.com/MichelinAdmin/#!login",
			target: '_blank',
		},
	},
	prod: {
		auth: {
			...authDefaults,
			domain: "auth.misp-solutions.com",
			clientID: "SRlo6HmR6kbbSP7XUF2e2m6EuQfuq60M",
			//clientID: "vALN06fQXbZ0lOPsKxlZAUhyX0RUNERT",
			pollingInterval: 15,
		},
		eventViewer: "https://event-viewer.oncall.misp-solutions.com/",
		liveTrack: {
			url: "https://live-track.oncall.misp-solutions.com",
			target: '_blank',
		},
		techAdmin: "https://oncall-web.oncall-prod.misp-solutions.com/",
		maestroCentral: {
			url: "https://central.production.misp-solutions.com/",
			target: '_blank',
		},
		esd: "https://esd-request.oncall.misp-solutions.com",
		dealerProfile: {
			url: "https://dealer-profile.core-profile.misp-solutions.com/",
			target: '_blank',
		},
		coreProfile: {
			allPrefs: "all-preferences/all-preferences",
			onCallPrefs: "oncall-tire/oncall-tire",
			bulkOrderPrefs: "bulk-order/bulk-order",
			url: "https://core-profile.misp-solutions.com/",
			target: '_blank',
		},
		dtsm: {
			url: "https://dtsm.core-profile.misp-solutions.com/",
			target: '_blank',
		},
		oncalldr: {
			url: "https://oncalldr.production.misp-solutions.com/",
			target: '_blank',
		},
		login: "https://www.michelinb2b.com/B2BIDP/jsp/html/LoginPage.jsp?redirecturl=https://auth.misp-solutions.com/login/callback?connection=B2B-SAML-SP&spid=004",
		fixPix: {
			url: "https://fixpix.services.michelintruck.com/MichelinAdmin/#!login",
			target: '_blank',
		},
	}
};

let config
const hostname = String(window.location.hostname)

if (includes(hostname, 'localhost') || includes(hostname, '.chaos')) {
	config = allConfigs.localhost
} else if (includes(hostname, '-dev.')) {
	config = allConfigs.dev
} else if (includes(hostname, '-test.')) {
	config = allConfigs.test
} else if (includes(hostname, '-staging.')) {
	config = allConfigs.stg
} else {
	config = allConfigs.prod
}

export default config
