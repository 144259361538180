import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { auth } from '../Auth';
import Callback from '../LandingPage/Callback'
import LandingPageComponent, { setCanvasMode, inIframe } from '../LandingPage/LandingPageComponent'

const handleAuthentication = ({ location }) => {
	if (/access_token|id_token|error/.test(location.hash)) {
		auth.handleAuthentication();
	} else {
		console.error('handleAuthentication failed', auth, location)
	}
};

class Routes extends Component {
	render() {
		return (
			<Switch>
				<Route
					path="/callback"
					render={props => {
						setCanvasMode(inIframe());
						handleAuthentication(props);
						return <Callback {...props} />;
					}}
				/>
				<Route path="/" exact component={LandingPageComponent} />
				<Redirect to="/" />
			</Switch>
		);
	}
}

export default Routes;
