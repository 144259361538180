import en from './en/translation.json'
import fr from './fr/translation.json'

let currentLanguage = window.navigator.language || window.navigator.userLanguage;
const defaultLanguage = 'en-US';

export function getCurrentLanguage() {
  return currentLanguage;
};

export function setCurrentLanguage(language) {
  currentLanguage = language;
}

export default function xlate(key) {
  if (!key) return '';

  const i18n = {
    'en': en,
    'en-GB': en,
    'en-CA': en,
    'en-US': en,
    'fr': fr,
    'fr-FR': fr,
    'fr-CA': fr,
  }
  
  let value = i18n[currentLanguage || defaultLanguage][key.toUpperCase()];

  if (value === undefined && currentLanguage !== defaultLanguage) {
    value = i18n[defaultLanguage][key.toUpperCase()]
  }

  return value || `${key} (${currentLanguage})`;
}