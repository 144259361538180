import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';

const styles = theme => ({
	link: {
		margin: theme.spacing.unit,
		cursor: "default",
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		margin: 'auto',
		width: 'fit-content',
	},
	formControl: {
		marginTop: theme.spacing.unit * 2,
		minWidth: 120,
	},
	formControlLabel: {
		marginTop: theme.spacing.unit,
	},
});

class TermsOfUseDialog extends React.Component {
	state = {
		open: false,
		fullWidth: true,
		maxWidth: 'xl',
	};

	handleClickOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	render() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				<Link color="primary" onClick={this.handleClickOpen} className={classes.link}>
					Terms of Use
        </Link>
				<Dialog
					fullWidth={this.state.fullWidth}
					maxWidth={this.state.maxWidth}
					open={this.state.open}
					onClose={this.handleClose}
					aria-labelledby="privacy-policy"
				>
					<DialogTitle id="max-width-dialog-title">Terms of Use</DialogTitle>
					<DialogContent>
						{/* <DialogContentText> */}
							<Typography variant="body1" gutterBottom>
								Thank you for visiting the
								<em>event-viewer.oncall.misp-solutions.com/</em> website (the
								<em>Site</em>). Please read carefully these Terms of Use before
								using this Site. By using this Site, you agree to these Terms of
								Use. If you do not accept these Terms of Use, please do not use the
								Site. We may revise these Terms of Use at any time and without
								notice to you.
          					</Typography>

							<Typography variant="h5" gutterBottom>Intended Audience and Use</Typography>
							<Typography variant="body1" gutterBottom>
								Michelin North America, Inc. (<em>Michelin</em>) provides this Site
								for the lawful use of its customers, employees, and members of the
								general public who are over the age of 13 and are citizens of the
								United States. Michelin makes no representation that the materials
								and products contained or displayed on this Site are appropriate or
								available for use in other locations. Those who access the Site from
								other locations do so at their own risk and are responsible for
								compliance with applicable local laws.
          					</Typography>

							<Typography variant="h5" gutterBottom>Use Of Site Content</Typography>
							<Typography variant="body1" gutterBottom>
								This Site contains Michelin's trademarks and copyrighted materials,
								including tradenames, logos, photographs, documents and software, as
								well as content submitted by other users of the Site. You are only
								authorized to use this material for personal, non-commercial
								purposes, including downloading and printing of materials, so long
								as this copyright permission notice is included in all such printed
								copies. No rights or licenses to the material or any portion thereof
								are granted, explicitly or impliedly, and you may not modify the
								material in any way without the prior written permission of
								Michelin. We enforce our intellectual property rights to the fullest
								extent of the law. If you intend to use any of the materials on this
								Site for other than private, non-commercial purposes, you must first
								obtain written permission from Michelin. Please consult our
								Copyright and Trademark statement for more information.
          					</Typography>

							<Typography variant="h5" gutterBottom>Links To Third-Party Websites</Typography>
							<Typography variant="body1" gutterBottom>
								This Site may provide links to third-party websites or resources.
								These links are provided solely as a convenience for you. Michelin
								has no control over these third-party websites and resources and
								makes no endorsement or representation about these websites, or the
								accuracy of the information and materials, advertising, products,
								services, or the results that you may obtain by using them. If you
								decide to access such third-party websites, you acknowledge that you
								do so entirely at your own risk.
          					</Typography>

							<Typography variant="h5" gutterBottom>Disclaimers of Warranties</Typography>
							<Typography variant="body1" gutterBottom>
								We endeavor to provide accurate and up-to-date information on this
								Site. However, the entire Site is provided to you on an "AS IS"
								basis, and any use of this Site is entirely at your own risk. We
								make no representations or warranties, express or implied, with
								respect to this Site or its operation. WE EXPRESSLY DISCLAIM ALL
								WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE
								WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. The
								information provided on this Site is of a general nature. Tire usage
								recommendations may be affected by factors such as geographic
								locale, vehicle age and condition, and personal driving habits.
								Please consult your authorized tire dealer or representative before
								making any purchasing decisions. This paragraph applies ONLY to your
								use of the Site and to the content that Michelin makes available to
								you from the Site. The Terms of Use do not provide any warranties on
								any products or services purchased from the Site. Any warranties
								provided on products or services purchased from the Site will be
								provided in and subject to the the terms of sale for those
								purchases.
          					</Typography>

							<Typography variant="h5" gutterBottom>Limitation of Liability</Typography>
							<Typography variant="body1" gutterBottom>
								IN NO EVENT, INCLUDING BUT NOT LIMITED TO ITS OWN NEGLIGENCE, SHALL
								MICHELIN BE LIABLE FOR ANYCONSEQUENTIAL, INDIRECT, SPECIAL,
								EXEMPLARY OR INCIDENTAL DAMAGES ARISING OUT OF THE USE OF OR
								INABILITY TO USE THIS SITE. MICHELIN'S AGGREGATE LIABILITY FOR ALL
								DAMAGES ARISING OUT OF OR RELATED TO THE USE OF THIS SITE IS LIMITED
								TO THE SUM OF $100.00.
          					</Typography>

							<Typography variant="h5" gutterBottom>Consumer Reviews and Consumer-Provided Content</Typography>
							<Typography variant="body1" gutterBottom>
								Some of our web pages may provide access to a message board under
								the title "User Tire Ratings &amp; Reviews" that permits consumers to
								share their views and experiences concerning our products. Michelin
								does not sponsor, support, or endorse comments that are posted on
								this message board. You are solely responsible for the information,
								data, opinions, messages, comments, photos, videos, graphics, sounds
								and other content or material that you submit, upload, post or
								otherwise make available on or through the Site (each a
								"Submission"), and you must abide by these Terms of Use, and the
								Terms of Use and any other policies of Bazaarvoice, the third party
            					that collects the Submissions.
							</Typography>
							<Typography variant="body1" gutterBottom>
								If you make a Submission, you must abide by the following
								guidelines:
							</Typography>
							<ul>
								<li>
									Your Submissions must not contain any statement that you know to
									be false, inaccurate, or misleading.
								</li>
								<li>Your Submissions must not violate any law.</li>
								<li>
									Your Submissions must not be defamatory, libelous, obscene,
									hateful, threatening, harassing, sexist, or racially, ethnically
									or religiously biased or offensive.
								</li>
								<li>
									You must not be compensated or granted any consideration by
									Michelin, any affiliate of Michelin, or any third party, for your
									Submissions.
								</li>
								<li>
									Your Submissions must not infringe the copyright, trademark,
									patent, trade secret, or other intellectual property rights (legal
									or moral) of any third party.
								</li>
							</ul>
							<Typography variant="body1" gutterBottom>
								Although Michelin does not have an obligation to police, monitor, or
								evaluate submitted comments, it reserves the right to remove any
								comment from the Site that violates these guidelines.
          					</Typography>
							<Typography variant="body1" gutterBottom>
								Unless otherwise explicitly stated herein or in our Privacy Policy, you agree that any
								Submission provided by you in connection with this Site is provided
								on a non-proprietary and non-confidential basis. By posting a
								comment or providing a Submission, you grant Michelin an
								irrevocable, perpetual, transferable, non-exclusive, royalty-free
								fully-paid worldwide license (including the right to sublicense
								through multiple tiers) to use, reproduce, publish, process, adapt,
								publicly perform, publicly display, modify, prepare derivative
								works, transmit and distribute, in whole or in part, your Submission
								in any manner and in any form, medium or distribution method, now
								known or hereafter existing, known or developed, and authorize
								others to use the Submissions and for any purpose that Michelin
								deems appropriate. Michelin may modify or adapt your Submissions in
								order to transmit, display or distribute them over computer networks
								and in various media and/or make changes to the Submissions as
								necessary to conform and adapt them to any requirements or
								limitations of any networks, devices, services or media. Michelin
								agrees to use any personally identifiable information contained in
								any of your Submissions in accordance with ourPrivacy Policy.
							</Typography>

							<Typography variant="h5" gutterBottom>Unauthorized Activities</Typography>
							<Typography variant="body1" gutterBottom>
								In addition to abiding by the guidelines above regarding any
								comments or Submissions you provide, when using this Site, you agree
								not to:
							</Typography>
							<ul>
								<li>Discuss or incite illegal activity.</li>
								<li>
									Post anything that exploits children or minors or that depicts
									cruelty to animals.
								</li>
								<li>
									Disseminate any unsolicited or unauthorized advertising,
									promotional materials, 'junk mail', 'spam', 'chain letters',
									'pyramid schemes', or any other form of such solicitation.
								</li>
								<li>
									Use any robot, spider, scraper or other automated means to access
									the Site.
								</li>
								<li>
									Take any action that imposes an unreasonable or disproportionately
									large load on our infrastructure.
								</li>
								<li>
									Alter the opinions or comments posted by others on this Site.
								</li>
								<li>
									Post anything contrary to our public image, goodwill or
									reputation.
								</li>
							</ul>
							<Typography variant="body1" gutterBottom>
								This list of prohibitions in these Terms of Use provides examples
								and is not complete or exclusive. Michelin reserves the right to (a)
								terminate access to your account, your ability to post to this Site
								and (b) refuse, delete or remove any submissions; with or without
								cause and with or without notice, for any reason or no reason, or
								for any action that Michelin determines is inappropriate or
            					disruptive to this Site or to any other user of this Site.
								<span style={{ fontWeight: 600 }}>
									Michelin may report to law enforcement authorities any actions
									that may be illegal, and any reports it receives of such conduct.
									When legally required or at Michelin's discretion, Michelin will
									cooperate with law enforcement agencies in any investigation of
									alleged illegal activity on this Site or on the Internet.
            					</span>
							</Typography>
							<Typography variant="body1" gutterBottom>
								Unauthorized use of any materials or third-party content contained
								on this Site may violate certain laws and regulations.
          					</Typography>
							<Typography variant="body1" gutterBottom>
								You agree to indemnify and hold Michelin and its officers,
								directors, employees, affiliates, agents, licensors, and business
								partners harmless from and against any and all costs, damages,
								liabilities, and expenses (including attorneys' fees and costs of
								defense) Michelin or any other indemnified party suffers in relation
								to, arising from, or for the purpose of avoiding, any claim or
								demand from a third-party that your use of this Site or the use of
								this Site by any person using your user name and/or password
								(including without limitation, your participation in the posting
								areas or, your submissions) violates any applicable law or
								regulation, or the trademark, copyright, patent, trade secret or
								other intellectual property rights, proprietary rights, rights of
								publicity or privacy, or other similar rights of any third party.
          					</Typography>

							<Typography variant="h5" gutterBottom>Proprietary Rights</Typography>
							<Typography variant="body1" gutterBottom>
								Any word/phrase marked with a ® or ™ is a trademark of Michelin in
								the United States. Other trademarks, names and logos on this Site
								are the property of their respective owners.
          					</Typography>

							<Typography variant="body1" gutterBottom>
								Unless otherwise specified in these Terms of Use, all information
								and screens appearing on this Site, including documents, services,
								site design, text, graphics, logos, images and icons, as well as the
								arrangement thereof, are the sole property of Michelin. All rights
								not expressly granted herein are reserved. Except as otherwise
								required or limited by applicable law, any reproduction,
								distribution, modification, retransmission, or publication of any
								copyrighted material is strictly prohibited without the express
								written consent of the copyright owner or license.
          					</Typography>

							<Typography variant="h5" gutterBottom>Intellectual Property Infringement</Typography>
							<Typography variant="body1" gutterBottom>
								Michelin respects the intellectual property rights of others, and we
								ask you to do the same. Michelin may, in appropriate circumstances
								and at our discretion, terminate service and/or access to this Site
								for users who infringe the intellectual property rights of others.
								If you believe that your work is the subject of copyright
								infringement and/or trademark infringement and appears on our Site,
								please provide Michelin's designated agent the following
								information:
							</Typography>
							<ul>
								<li>
									A physical or electronic signature of a person authorized to act
									on behalf of the owner of an exclusive right that is allegedly
									infringed.
								</li>
								<li>
									Identification of the copyrighted and/or trademarked work claimed
									to have been infringed, or, if multiple works at a single online
									site are covered by a single notification, a representative list
									of such works at that site.
								</li>
								<li>
									Identification of the material that is claimed to be infringing or
									to be the subject of infringing activity and that is to be removed
									or access to which is to be disabled at the Site, and information
									reasonably sufficient to permit Michelin to locate the material.
								</li>
								<li>
									Information reasonably sufficient to permit Michelin to contact
									you as the complaining party, such as an address, telephone
									number, and, if available, an electronic mail address at which you
									may be contacted.
								</li>
								<li>
									A statement that you have a good faith belief that use of the
									material in the manner complained of is not authorized by the
									copyright and/or trademark owner, its agent, or the law.
								</li>
								<li>
									A statement that the information in the notification is accurate,
									and under penalty of perjury, that you are authorized to act on
									behalf of the owner of an exclusive right that is allegedly
									infringed.
								</li>
							</ul>
							<Typography variant="body1" gutterBottom>
								Any claims of copyright or trademark infringement on this Site
            					should be directed to: 
								<a href="https://www.michelin.com/en/contact">
									https://www.michelin.com/en/contact
            					</a>
							</Typography>
							<Typography variant="body1" gutterBottom>
								Please also note that for copyright infringements under Section
								512(f) of the Copyright Act, any person who knowingly materially
								misrepresents that material or activity is infringing may be subject
								to liability.
          					</Typography>

							<Typography variant="h5" gutterBottom>Submitting a DMCA Counter-Notification</Typography>
							<Typography variant="body1" gutterBottom>
								We will notify you that we have removed or disabled access to
								copyright-protected material that you provided, if such removal is
								pursuant to a valid DMCA take-down notice that we have received. If
								you receive such notice from us, you may provide us with a
								counter-notification in writing to Michelin that includes all of the
								following information:
							</Typography>
							<ol>
								<li>Your physical or electronic signature;</li>
								<li>
									Identification of the material that has been removed or to which
									access has been disabled, and the location at which the material
									appeared before it was removed or access to it was disabled;
								</li>
								<li>
									A statement from you under the penalty of perjury, that you have a
									good faith belief that the material was removed or disabled as a
									result of a mistake or misidentification of the material to be
									removed or disabled; and
								</li>
								<li>
									Your name, physical address and telephone number, and a statement
									that you consent to the jurisdiction of a court for the judicial
									district in which your physical address is located, or if your
									physical address is outside of the United States, for any judicial
									district in which Michelin may be located, and that you will
									accept service of process from the person who provided
									notification of allegedly infringing material or an agent of such
									person.
								</li>
							</ol>

							<Typography variant="h5" gutterBottom>Termination of Repeat Infringers</Typography>
							<Typography variant="body1" gutterBottom>
								Michelin reserves the right, in its sole discretion, to terminate
								the account or access of any user of our web site and/or service who
								is the subject or repeated DMCA or other infringement notifications.
          					</Typography>

							<Typography variant="h5" gutterBottom>Our Commitment to Privacy</Typography>
							<Typography variant="body1" gutterBottom>
								Michelin is committed to protecting the privacy of your personally
            					identifiable information. Please consult our
								Privacy Policy for more information.
          					</Typography>

							<Typography variant="h5" gutterBottom>Governing Law</Typography>
							<Typography variant="body1" gutterBottom>
								The use of this Site and these Terms of Use shall be governed by and
								construed in accordance with the laws and of the State of South
								Carolina without giving effect to any principles of conflict of
								laws.
          					</Typography>

							<Typography variant="h5" gutterBottom>Effectiveness</Typography>
							<Typography variant="body1" gutterBottom>These Terms of Use are effective when posted.</Typography>
            {/* </DialogContentText> */}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							Accept
            </Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}
}

TermsOfUseDialog.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TermsOfUseDialog);
