import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Paper,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Icon,
  Avatar,
  CssBaseline,
  Typography,
  Link,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AppBarComponent from '../layout/AppBar/AppBarComponent';
import { AuthConsumer } from '../Auth';
import clsx from 'clsx';
import footerLogo from '@michelin/acid-components/assets/images/logos/Michelin_C_S_WhiteBG_RGB_0621-01.svg';

import xlate, { getCurrentLanguage, setCurrentLanguage } from '../i18n';
import tiles from './Tiles';
import messages from './Messages';
import TermsOfUseDialog from './TermsOfUseDialog';
import PrivayPolicyDialog from './PrivacyPolicyDialog';
import TermsOfUseDialogFrench from './TermsOfUseDialogFrench';
import PrivayPolicyDialogFrench from './PrivacyPolicyDialogFrench';
import LoadingBounce from './LoadingBounce';

const styles = (theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  toolbarTitle: {
    flex: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  heroContainer: {
    margin: '0 auto',
    padding: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 4}px 0`,
  },
  cardActive: {
    cursor: 'pointer',
  },
  cardDisabled: {
    cursor: 'not-allowed',
    opacity: '.5',
  },
  cardHeaderSmall: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.main,
    padding: 4,
    fontWeight: 'bold',
    // fontSize: "2em",
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.main,
    fontSize: '1.5em',
  },
  cardHeaderActive: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    cursor: 'pointer',
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  footer: {
    marginTop: theme.spacing.unit * 8,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit * 6}px 0`,
  },
  logo: {
    height: '100px',
  },
  footerLogo: {
    height: '90px',
    marginBottom: '-12px',
  },
  textHeader: {
    color: '#FFFFFF',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  avatarActive: {
    color: theme.palette.primary.main,
    backgroundColor: '#FFFFFF',
  },
  avatarSmall: {
    height: 24,
    width: 24,
  },
  img: {
    width: '50px',
    height: '50px',
  },
  imgSmall: {
    width: '24px',
    height: '24px',
  },
  titleText: {
    color: '#FFFFFF',
  },
  username: {
    color: '#FFFFFF',
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 4,
    backgroundColor: '#fff8dc',
  },
  link: {
    marginRight: theme.spacing.unit * 2,
  },
  linkIcon: {
    fontSize: '16px',
    position: 'relative',
    top: theme.spacing.unit / 2,
    marginRight: theme.spacing.unit / 2,
  },
});

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function setCanvasMode(mode) {
  sessionStorage.setItem('canvasMode', mode);
}

function getCanvasMode() {
  return sessionStorage.getItem('canvasMode');
}

function LandingPageAuthComponent(classes) {
  return (
    <AuthConsumer>
      {(props) => (
        <LandingPageComponent
          userProfile={props.auth.userProfile}
          {...props}
          {...classes}
        />
      )}
    </AuthConsumer>
  );
}

class LandingPageComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      language: getCurrentLanguage(),
      //   showWelcome: false,
      //   showUserForm: false,
      //   showVerifyEmail: false,
      //   userData: {}
    };
  }

  /**
   * True if tile does not require login.
   * If login required, check for login. False if not logged in.
   * Auth0 login required? False if B2B login is active.
   * Else return true.
   *
   * @memberof LandingPageComponent
   */
  checkLogin = (tile) => {
    if (!tile.requireLogin) return true;

    if (!this.props.userProfile) return false;

    // if (this.props.userProfile && this.props.userProfile.welcome_screen) {}

    if (tile.requireAuth0Login) {
      return this.props.userProfile.issuer !== 'michelinb2b.com';
    }

    return true;
  };

  launchButton = (tile) => {
    if (tile.key === 'techAdmin')
      if (this.checkLogin(tile)) {
        window.location = tile.link;
      }
  };

  // closeWelcomeModal = () => {
  //   this.setState({
  //     showWelcome: false,
  //     showUserForm: true
  //   })
  // }

  // submitUserProfile = () => {
  //   this.setState({
  //     showUserForm: false,
  //     showVerifyEmail: true
  //   })
  // }

  // closeProfileModal = () => {
  //   this.setState({
  //     showUserForm: false,
  //     showVerifyEmail: true
  //   })
  // }

  // resendLink = () => {
  //     console.log('Link send to your email account.');
  //     this.setState({
  //       showVerifyEmail: false
  //     })
  // }

  // closeVerifyModal = () => {
  //   this.setState({
  //     showVerifyEmail: false
  //   })
  // }

  handleLanguage = (language) => {
    setCurrentLanguage(language);
    this.setState({ language });
  };

  render() {
    const { classes } = this.props;
    const { language } = this.state;
    const canvasMode = getCanvasMode();
    if (canvasMode === 'true') {
      return <LoadingBounce style={{ marginTop: '50vh' }} />;
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBarComponent
          language={language}
          setCurrentLanguage={(language) => this.handleLanguage(language)}
        />
        <main className={classes.layout}>
          <div className={classes.heroContainer}>
            <Typography variant='body1' align='center' gutterBottom>
              {xlate('landing.launch')}
            </Typography>
            <Typography variant='body1' align='center' gutterBottom>
              {xlate('landing.authenticate')}
            </Typography>
          </div>

          {messages
            .filter((message) => message.visible)
            .map((message) => (
              <Paper className={classes.paper} elevation={4} key={message.key}>
                <Typography variant='h5' component='h3'>
                  {xlate(message.title)}
                </Typography>
                <Typography component='p'>
                  {xlate(message.description)}
                </Typography>
                {message.links.map((link) => (
                  <Link
                    key={link.key}
                    href={link.href}
                    className={classes.link}
                  >
                    <Icon fontSize='small' className={classes.linkIcon}>
                      {link.icon}
                    </Icon>
                    {link.label}
                  </Link>
                ))}
              </Paper>
            ))}

          <Grid container alignItems='stretch' alignContent='stretch'>
            {tiles
              .filter((tile) => tile.visible(this.props.userProfile))
              .map((tile) => (
                <Grid
                  item
                  key={tile.key}
                  style={{ padding: 8 }}
                  {...tile.sizes}
                >
                  <Link
                    underline='none'
                    href={
                      this.checkLogin(tile)
                        ? tile.link && tile.link.url
                          ? tile.link.url
                          : tile.link
                        : undefined
                    }
                    target={
                      tile.link && tile.link.url ? tile.link.url : '_blank'
                    }
                  >
                    <Card
                      raised
                      className={
                        this.checkLogin(tile)
                          ? classes.cardActive
                          : classes.cardDisabled
                      }
                    >
                      <CardHeader
                        avatar={
                          <Avatar
                            className={clsx(
                              classes.avatar,
                              classes.avatarSmall,
                              this.checkLogin(tile) && classes.avatarActive
                            )}
                          >
                            {tile.key === 'maestroCentral' ? (
                              <img
                                className={classes.imgSmall}
                                src={tile.image}
                                alt='Michelin Central'
                              />
                            ) : (
                              <Icon fontSize='small'>{tile.image}</Icon>
                            )}
                          </Avatar>
                        }
                        title={xlate(tile.title)}
                        subheader={xlate(tile.subheader)}
                        disableTypography={true}
                        titleTypographyProps={{ align: 'center' }}
                        subheaderTypographyProps={{ align: 'center' }}
                        className={clsx(
                          classes.cardHeaderSmall,
                          this.checkLogin(tile) && classes.cardHeaderActive
                        )}
                      />
                      <CardContent style={{ marginBotton: 0 }}>
                        {tile.description && (
                          <Typography variant='body1'>
                            {xlate(tile.description)}
                          </Typography>
                        )}
                        {tile.callout && tile.callout.title && (
                          <Typography
                            variant='body2'
                            style={{ fontSize: '1.2em', fontWeight: 'bold' }}
                          >
                            {xlate(tile.callout.title)}
                          </Typography>
                        )}
                        {tile.callout && tile.callout.description && (
                          <Typography variant='body1'>
                            {xlate(tile.callout.description)}
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              ))}
          </Grid>
        </main>

        {/* Footer */}
        <footer className={classNames(classes.footer, classes.layout)}>
          <Grid container spacing={32} justify='center'>
            <Grid item>
              <img
                alt='Home Page'
                src={footerLogo}
                className={classes.footerLogo}
              />
              <Typography variant='caption' align='center'>
                {xlate('landing.copyright')}
                {language === 'fr-CA' ? (
                  <>
                    <TermsOfUseDialogFrench /> | <PrivayPolicyDialogFrench />
                  </>
                ) : (
                  <>
                    <TermsOfUseDialog /> | <PrivayPolicyDialog />
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid>
            <WelcomeComponent
              showModal={this.state.showWelcome}
              onSubmit={this.closeWelcomeModal}
            />
            <UserProfileForm 
              showModal={this.state.showUserForm}
              onSubmit={this.submitUserProfile}
              // onClose={this.closeProfileModal}
            />
            <VerifyEmailComponent 
              showModal={this.state.showVerifyEmail}
              onSubmit={this.resendLink}
              onClose={this.closeVerifyModal}
            />
          </Grid> */}
        </footer>
        {/* End footer */}
      </React.Fragment>
    );
  }
}

LandingPageComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

LandingPageAuthComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LandingPageAuthComponent);

export { setCanvasMode, getCanvasMode, inIframe };
