import React from 'react';
import styled, { keyframes } from 'styled-components';

export default function LoadingBounce(props) {
  const size = props.size || '1.75vh';
  const color = props.color || '#7e7e7e';
  const margin = props.margin || '.25rem';

  const bouncedelay = keyframes`
        0%,
        80%,
        100% {
            -webkit-transform: scale(0);
            transform: scale(0);
        }

        40% {
            -webkit-transform: scale(1.0);
            transform: scale(1.0);
        }
    `;

  const Spinner = styled.div`
    display: flex;
    justify-content: center;
  `;
  const Bounce = styled.div`
    width: ${size};
    height: ${size};
    background-color: ${color};
    margin: 0 ${margin};
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: ${bouncedelay} 1.4s infinite ease-in-out both;
    animation: ${bouncedelay} 1.4s infinite ease-in-out both;
  `;

  const Bounce1 = styled(Bounce)`
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  `;

  const Bounce2 = styled(Bounce)`
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  `;

  return (
    <Spinner {...props}>
      <Bounce1 />
      <Bounce2 />
      <Bounce />
    </Spinner>
  );
}
